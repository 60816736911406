@import "./variables.scss";

@font-face {
  font-family: 'FordF-1';
  src: url('./assets/fonts/FordF-1-Light.otf') format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: 'FordF-1';
  src: url('./assets/fonts/FordF-1-Regular.otf') format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: 'FordF-1';
  src: url('./assets/fonts/FordF-1-Medium.otf') format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: 'FordF-1';
  src: url('./assets/fonts/FordF-1-Semibold.otf') format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: 'FordF-1';
  src: url('./assets/fonts/FordF-1-Bold.otf') format("truetype");
  font-weight: 700;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  font-family: "FordF-1";
}

body {
  margin: 0px;
  color: #00095B;
  min-height: 100%;
  background: #ffffff;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}